import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

//import { Drops as NeftyDrops } from '@neftyblocks/drops';

import '@neftyblocks/drops';
import { UserData } from '../components/interfaces/InterfaceData';

const Mints = ({ user, nftData, setLoginUser, logOut }) => {

    return (
        <div>
            <Header user={user} setLoginUser={setLoginUser} logOut={logOut} />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Mints</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Explore</Link></li>
                                    <li>Mints</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>

            <section className="tf-section" style={{ 'padding': 50 }}>
                {nftData?.collectionName && (
                    <neftyblocks-drops collection={nftData?.collectionName} ></neftyblocks-drops>
                )}
            </section>
            <Footer />
        </div>
    );
}


export default Mints;
