import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';

import { AtomicUsersData } from '../interfaces/InterfaceData';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


const TopHolders = () => {
    const [holderInfo, setHolderInfo] = useState<AtomicUsersData[]>();

    useEffect(() => {
        if (!holderInfo || holderInfo.length === 0) {
            getOwnersFromApi().then((tst: AtomicUsersData[]) => {
                setHolderInfo(tst);
            });
        }
        console.log(holderInfo);
    }, [holderInfo]);

    const getOwnersFromApi = (async (): Promise<AtomicUsersData[]> => {
        try {
            let response = await fetch('https://wax.api.atomicassets.io/atomicassets/v1/accounts?collection_name=sgminiwizard&page=1&limit=100&order=desc');
            let responseJson = await response.json();
            return responseJson.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    });

    return (
        <section className="tf-section top-seller">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-live-auctions">
                            <h2 className="tf-title">
                                Top Holders</h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                    {holderInfo && (
                        <Swiper
                            modules={[Navigation , Scrollbar, A11y]}
                            spaceBetween={30}
                            navigation
                            breakpoints={{
                                0: {
                                    slidesPerView: 2,
                                },
                                767: {
                                slidesPerView: 5,
                                },
                                991: {
                                slidesPerView: 7,
                                },
                                1200: {
                                    slidesPerView: 8,
                                },
                                1350: {
                                slidesPerView: 9,
                                },
                            }}
                            scrollbar={{ draggable: true }}                
                        >
                            {
                                holderInfo.map((item,index) => (
                                    <SwiperSlide key={index}>
                                        <TopHolderItem item={item} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    )}
                    </div>
                </div>
            </div>
        </section>
    );
}

const TopHolderItem = (props: { item: AtomicUsersData }) => (
    <div className="swiper-container seller style2 seller-slider2 button-arow-style">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">										
                    <div className="sc-author-box style-2">
                        <div className="author-avatar">
                            <img src={'https://profile.api.atomichub.io/v1/profiles/chain/wax-mainnet/account/' + props.item.account + '/avatar'} alt="" className={props.item.account + ' avatar'} />
                            <div className="badge"></div>
                        </div>
                        <div className="author-infor">
                            <h5><Link target='_blank' to={'https://wax.atomichub.io/profile/wax-mainnet/' + props.item.account}>{props.item.account}</Link></h5>
                            <span className="price">Assets: {props.item.assets}</span>
                        </div>
                    </div>    	
                </div>
            </div>
        </div>
    </div>
)

export default TopHolders;
