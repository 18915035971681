import img1 from '../images/slider/slide_1.png';
import img2 from '../images/slider/slide_1.png';
import imgbg1 from '../images/slider/bg_slide_1.png'
import imgbg2 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "Craft & Play with",
        title_2: "Mini-Wizards",
        title_3: "NFTs",
        description: "WAX-Chain collection with GameFi Utility!",
        img: img1,
        imgbg: imgbg1,
        class:'left'
    }
]

export default heroSliderData;