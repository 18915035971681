import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import { UserData } from '../components/interfaces/InterfaceData';
import { Unity, useUnityContext } from "react-unity-webgl";

const Battle = ({ user, nftData, setLoginUser, logOut }) => {

    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: "assets/build/AnchorUnityWebGL.loader.js",
        dataUrl: "assets/build/webgl.data",
        frameworkUrl: "assets/build/build.framework.js",
        codeUrl: "assets/build/build.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "Dangerous Dragon",
        productName: "Mini-Wizards - Battle of Elements",
        productVersion: "0.2",
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <div>
            <Header user={user} setLoginUser={setLoginUser} logOut={logOut} />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Battle of Elements</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li>Bring your Mini-Wizard</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>

            <div id="unity-container" className="unity-desktop">
                {isLoaded === false && (
                    // We'll conditionally render the loading overlay if the Unity
                    // Application is not loaded.
                    <div className="loading-overlay">
                        <p>Loading... ({loadingPercentage}%)</p>
                    </div>
                )}
                <Unity className="unity" unityProvider={unityProvider} />
                <div id="unity-loading-bar">
                    <div id="unity-logo"></div>
                    <div id="unity-progress-bar-empty">
                        <div id="unity-progress-bar-full"></div>
                    </div>
                </div>
                <div id="unity-warning"> </div>
                <div id="unity-footer">
                    <div id="unity-webgl-logo"></div>
                    <div id="unity-fullscreen-button">Full Screen</div>
                    <div id="unity-build-title">WAX Mini-Wizards - Battle of Elements</div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


export default Battle;
