import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider';
import Slider from '../components/slider/Slider';
import LatestBlends from '../components/layouts/LatestBlends';
import TopSeller from '../components/layouts/TopHolders';
import topSellerData from '../assets/fake-data/data-top-seller'
import LatestMints from '../components/layouts/UnpackedBoosters';
import Information from '../components/layouts/Information';

const Home = ({ user, nftData, setLoginUser, logOut }) => {

    return (
        <div className='home-1'>
            <Header user={user} nftData={nftData} setLoginUser={setLoginUser} logOut={logOut} />
            <Slider data={heroSliderData} />
            <LatestBlends />
            <LatestMints />
            <TopSeller data={topSellerData} />
            <Information />
            <Footer />
        </div>
    );
}

export default Home;
