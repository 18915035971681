import React from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../assets/images/icon/chaos.png'
import icon2 from '../../assets/images/icon/earth.png'
import icon3 from '../../assets/images/icon/love.png'
import icon4 from '../../assets/images/icon/fire.png'

interface InformationBlock {
    title: string,
    description: string,
    icon: string,
    colorbg: string,
}

const Information = () => {
    const data: InformationBlock[] = [
        {
            title: "Collect a booster pack",
            description: "Buy yourself a booster pack to start with, after opening this pack of cards you will get resource cards which you will need to blend into battle ready Mini-Wizards.",
            icon : icon1,
            colorbg : "icon-color1"
        },
        {
            title: "Blend resources",
            description: "After opening booster packs you will get different resource cards, every card is used to create an unique Mini-Wizard. Gotta collect them all!",
            icon : icon2,
            colorbg : "icon-color2"
        },
        {
            title: "Create Mini-Wizards",
            description: "Got enough resource cards to blend a Mini-Wizard, but it won't stop here. Every Mini-Wizard has a 'Tier' level which you can upgrade to the next tier with enough resources.",
            icon : icon3,
            colorbg : "icon-color3"
        },
        {
            title: "Battle against others",
            description: "With all the Mini-Wizards you hold, you will be able to battle other contestants in a Battle Arena. After the Mini-Wizard gets killed you won't be able to use it again that match, holding multiple is wise!",
            icon : icon4,
            colorbg : "icon-color4"
        },
    ]
    return (
        <section className="tf-box-icon create style1 tf-section">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-live-auctions mg-bt-22">
                            <h2 className="tf-title pb-17">
                                Mini-Wizards, Battle of Elements</h2>                         
                        </div>
                    </div>
                    {
                        data.map((item: InformationBlock, index: number) => (
                            <CreateInformation key={index} item={item} />
                        ))
                    }
                </div>                 
            </div>
        </section>
    );
}

const CreateInformation = (props: { key: number, item: InformationBlock }) => (
    <div className='col-lg-3 col-md-6 col-12'>
        <div className="sc-box-icon">
            <div className="image">
                <div className={`icon-create ${props.item.colorbg}`}>
                    <img src={props.item.icon} alt="" />
                </div>                                                                             
            </div>
            <h3 className="heading">{props.item.title}</h3>
            <p className="content">{props.item.description}</p>
        </div>
    </div>
)

export default Information;
