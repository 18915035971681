import React , { useState , Fragment, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { AtomicData } from '../../components/interfaces/InterfaceData';

const UnpackedBoosters = () => {
    const [unpackedInfo, setUnpackedInfo] = useState<AtomicData[]>();

    useEffect(() => {
        if (!unpackedInfo || unpackedInfo.length === 0) {
            getUnpacksFromApi().then((tst: AtomicData[]) => {
                setUnpackedInfo(tst);
            });
        }
        console.log(unpackedInfo);
    }, [unpackedInfo]);

    const getUnpacksFromApi = (async (): Promise<AtomicData[]> => {
        try {
            let response = await fetch('https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=sgminiwizard&page=1&limit=8&order=desc');
            let responseJson = await response.json();
            return responseJson.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    });

    return (
        <Fragment>
        <section className="tf-section today-pick">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-live-auctions mg-bt-21">
                            <h2 className="tf-title pad-l-7">
                                Latest Mints
                            </h2>
                        </div>
                    </div>
                    {unpackedInfo && (
                        <>
                        { 
                            unpackedInfo.map((item: AtomicData,index: React.Key | null | undefined) => (
                                <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div className={`sc-card-product`}>
                                        <div className="card-media">
                                            <Link target='_blank' to={'https://wax.atomichub.io/explorer/asset/wax-mainnet/' + item.asset_id}><img src={'https://ipfs.neftyblocks.io/ipfs/' + item.data.img} alt={item.name} /></Link>
                                            <div className="featured-countdown">
                                                <span className="slogan"></span>
                                                <span>{new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: '2-digit', month: 'numeric', day: '2-digit' }).format(item.minted_at_time)}</span>
                                            </div>
                                        </div>
                                        <div className="card-title">
                                            <h5><Link target='_blank' to={'https://wax.atomichub.io/explorer/asset/wax-mainnet/' + item.asset_id}>{item.data.name}</Link></h5>
                                            <div className="tags">#{item.template_mint}</div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="info">
                                                    <span>Owned By</span>
                                                    <h6> <Link target='_blank' to={'https://wax.atomichub.io/profile/wax-mainnet/' + item.owner}>{item.owner}</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </>
                    )}
                </div>
            </div>
        </section>
        </Fragment>
    );
}



UnpackedBoosters.propTypes = {
    data: PropTypes.array.isRequired,
}


export default UnpackedBoosters;
