import React , { useState , Fragment, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link, To } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import CardModal from './CardModal'
import { AtomicData } from '../../components/interfaces/InterfaceData';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const LatestBlends = () => {
    const [blendInfo, setBlendInfo] = useState<AtomicData[]>();

    useEffect(() => {
        if(!blendInfo || blendInfo.length === 0) {
            getBlendsFromApi().then((tst: AtomicData[] )=> {
                setBlendInfo(tst);
            });
        }
    }, [blendInfo]);

    const getBlendsFromApi = (async (): Promise<AtomicData[]> => {
        try {
            let response = await fetch('https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=sgminiwizard&schema_name=sgmw&page=1&limit=25&order=desc');
            let responseJson = await response.json();
            return responseJson.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    });

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title pb-20">
                                    Latest Blends
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {blendInfo && (
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}

                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                    slidesPerView: 2,
                                    },
                                    991: {
                                    slidesPerView: 3,
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                    {
                                        blendInfo.slice(0,7).map((item: AtomicData ,index: React.Key | null | undefined) => (
                                            <SwiperSlide key={index}>
                                                    <div className="swiper-container show-shadow carousel auctions">
                                                        <div className="swiper-wrapper">
                                                            <div className="swiper-slide">
                                                                <div className="slider-item">										
                                                                    <div className="sc-card-product">
                                                                        <div className="card-media">
                                                                            <Link target='_blank' to={'https://wax.atomichub.io/explorer/asset/wax-mainnet/' + item.asset_id}><img src={'https://ipfs.neftyblocks.io/ipfs/' + item.data.img} alt={item.name} /></Link>
                                                                            <div className="featured-countdown">
                                                                                <span className="slogan"></span>
                                                                            <span>{new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.minted_at_time)}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-title">
                                                                            <h5><Link target='_blank' to={'https://wax.atomichub.io/explorer/asset/wax-mainnet/' + item.asset_id}>{item.data.name}</Link></h5>
                                                                            <div className="tags">#{item.template_mint}</div>
                                                                        </div>
                                                                        <div className="meta-info">
                                                                            <div className="author">
                                                                                <div className="info">
                                                                                    <span>Owned By</span>
                                                                                    <h6> <Link target='_blank' to={'https://wax.atomichub.io/profile/wax-mainnet/' + item.owner}>{item.owner}</Link> </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>    	
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </SwiperSlide>
                                        ))
                                    }
                            </Swiper>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
        
    );
}

LatestBlends.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LatestBlends;
