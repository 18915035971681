/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { useEffect, useState } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import * as wax from "@waxio/waxjs/dist";

import Home from "./pages/Home";
import Mints from "./pages/Mints";
import Blends from "./pages/Blends";
import Market from "./pages/Market";
import { UserInfo, NftData, AssetData } from './components/interfaces/InterfaceData';
import Battle from './pages/Battle';

function App() {
    const [user, setUser] = useState<UserInfo>();
    const [nftData, setNftData] = useState<NftData>();
    const [loginUser, setLoginUser] = useState<boolean>(false);

    

    useEffect(() => {
        console.log("check")
        if (loginUser === true) {
            console.log('login!')
            logIn();
        }
        
        setNftData({
            collectionName: 'sgminiwizard',
            mintPriceBoosterGen1: 25,
        })
    }, [loginUser])
    
    let waxInstance: any;

    const logIn = (async () => {
        if (!loginUser) return;
        //console.log("LOG INNNN!");
        try {
            /*if (!waxInstance) {
                console.error('WAX Cloud Wallet not detected.');
                return;
            }*/

            waxInstance = new wax.WaxJS({
                rpcEndpoint: 'https://wax.greymass.com',
            });
            await waxInstance.login();

            const data: UserInfo = {
                username: waxInstance.userAccount,
                avatar: waxInstance.avatar,
                trustScore: waxInstance.trustScore,
                balance: await fetchWaxBalance(waxInstance.userAccount),
                userAssets: await fetchAssets(waxInstance.userAccount),
            }

            setUser(prevState => ({
                ...prevState,
                ...data
            }));

            console.log(data);
            console.log(user);

        } catch (error) {
            console.error('Login with WAX Cloud Wallet failed:', error);
            setLoginUser(false);
        }
    });

    const logOut = () => {
        console.log("LOG OUT!");
        setLoginUser(false);
        //setUser(undefined);
    };

    const fetchAssets = async (username: string): Promise<any> => {
        console.log("FETCH ASSETS!");
        const limit = 1000; // Adjust the limit as per your requirement
        let assets: AssetData[] = [
            {
                collection_name: 'test',
                asset_id: 'dfgdfg',
                amount: 0,
            }
        ];

        try {
            //waxInstance.api.rpc.setEndpoint = API_URL;
            const response = await waxInstance.api.rpc.get_table_rows({
                json: true,
                code: 'atomicassets',
                scope: username,
                table: 'assets',
                index_position: 1,
                limit,
            });

            if (nftData) {
                let existingValue = false;
                response.rows.forEach((asset: AssetData) => {
                    if (asset.collection_name === nftData.collectionName) {
                        assets.forEach(
                            (item) => {
                                if (item.collection_name === nftData.collectionName) {
                                    item.amount++;
                                    existingValue = true;
                                }
                            }
                        );

                        if (!existingValue) {
                            let newAsset = {
                                    collection_name: asset.collection_name,
                                    asset_id: asset.asset_id,
                                    amount: 1,
                            };
                            
                            assets.push(newAsset);
                        }
                    }
                });
            }

            const assetsWithHolding = Object.values(assets);

            return(assetsWithHolding);
        } catch (error) {
            console.error('Fetch assets failed:', error);
            return undefined;
        }
    };

    const fetchWaxBalance = async (username: string): Promise<number> => {
        console.log("CHECK WAX!");
        try {
            const balance = await waxInstance.api.rpc.get_currency_balance('eosio.token', username, 'WAX');
            //setWaxBalance(parseFloat(balance[0]));
            return parseFloat(balance[0]);
        } catch (error) {
            console.error('Fetch WAX balance failed:', error);
            return 0;
        }
    };

    return (
        <Routes>
            <Route path="/" element={<Home user={user} nftData={nftData} setLoginUser={setLoginUser} logOut={logOut} />} />
            <Route path="/mints" element={<Mints user={user} nftData={nftData} setLoginUser={setLoginUser} logOut={logOut} />} />
            <Route path="/blends" element={<Blends user={user} nftData={nftData} setLoginUser={setLoginUser} logOut={logOut} />} />
            <Route path="/market" element={<Market user={user} nftData={nftData} setLoginUser={setLoginUser} logOut={logOut} />} />

            <Route path="/battle" element={<Battle user={user} nftData={nftData} setLoginUser={setLoginUser} logOut={logOut} />} />
        </Routes>
    );
}

export default App;
