import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import '@neftyblocks/blends';
import { UserData } from '../components/interfaces/InterfaceData';

const Blends = ({ user, nftData, setLoginUser, logOut }) => {
    const blendConfig = {
        atomic_url: 'https://aa.neftyblocks.com',
        chain_url: 'https://wax.neftyblocks.com',
        platform_url: 'https://neftyblocks.com',
        chain: 'wax',
        collection: 'sgminiwizard'
    };

    return (
        <div className='explore'>
            <Header user={user} nftData={nftData} setLoginUser={setLoginUser} logOut={logOut} />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Blends</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Explore</Link></li>
                                    <li>Blends</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            
            <section className="tf-section" style={{ 'padding': 50 }}>
                {nftData?.collectionName && (
                    <neftyblocks-blends config={JSON.stringify(blendConfig)}></neftyblocks-blends>
                )}
            </section>
            <Footer />
        </div>
    );
}

export default Blends;
