const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 10,
        name: 'Explore',
        links: '#',
        namesub: [
            {
                id: 11,
                sub: 'Mints',
                links: '/mints'
            },
            {
                id: 12,
                sub: 'Blends',
                links: '/blends'
            },
            {
                id: 13,
                sub: 'Marketplace',
                links: '/market'
            },
        ],
    },{
        id: 20,
        name: 'Resources',
        links: '#',
        namesub: [
            {
                id: 21,
                sub: 'Start Adventures',
                links: '#'
            },
            {
                id: 22,
                sub: 'Create Materials',
                links: '#',
            },
            {
                id: 23,
                sub: 'Brew Potions',
                links: '#',
            },
        ],
    },
    {
        id: 30,
        name: 'Info',
        links: '#',
        namesub: [
            {
                id: 31,
                sub: 'Gamepaper',
                links: '#'
            },
            {
                id: 32,
                sub: 'What are adventures',
                links: '#',
                target: '_this'
            },
            {
                id: 33,
                sub: 'What are materials',
                links: '#'
            },
            {
                id: 34,
                sub: 'What are potions',
                links: '#'
            },
            {
                id: 35,
                sub: 'What are Mini-Wizards',
                links: '#'
            },
            {
                id: 36,
                sub: 'How to play',
                links: '#'
            }
        ],
    },
    {
        id: 50,
        name: 'BATTLE!',
        links: '/battle',
    },
    {
        id: 70,
        name: 'Contact',
        links: '#',
        namesub: [
            {
                id: 71,
                sub: 'Discord',
                links: 'https://discord.gg/PJYgppGKYz',
                target: '_blank'
            },
            {
                id: 72,
                sub: 'Twitter',
                links: 'https://twitter.com/SGMiniWizards',
                target: '_blank'
            },
            {
                id: 73,
                sub: 'AtomicHub',
                links: 'https://wax.atomichub.io/explorer/collection/wax-mainnet/sgminiwizard',
                target: '_blank'
            },
            {
                id: 74,
                sub: 'NeftyBlocks',
                links: 'https://neftyblocks.com/collection/sgminiwizard',
                target: '_blank'
            }
        ],
    },
    
]

export default menus;